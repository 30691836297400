import React from 'react';
import './App.css';
import { Button, WhiteSpace } from 'antd-mobile';
import Bind from './Bind'
import Student from './Student'
import StudentList from './StudentList'
import MimaError from './MimaError'
import { BrowserRouter, Route } from 'react-router-dom';
import {axiosGet} from './server/serverapp'



class indexpage extends React.Component {
  async componentDidMount() {
      const islogin = await axiosGet('histOpen');
      console.log(islogin.retCode,islogin.retData.sutdent_list)
      if(islogin.retCode === 0 && islogin.retData.sutdent_list.length > 0){
        console.log(2)
        this.props.history.replace('/studentlist')
      }
  }

  toBind = () => {
    this.props.history.push('/bind')
  }
  render() {

    return (
      <div className="App">
        <WhiteSpace />
        <WhiteSpace />
        <div className="tittle">
          亲爱的家长，欢迎使用【英语说家长通】！
    </div>
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <p>
          绑定孩子账号后
    </p>
        <p>
          可免费接收作业通知和学情报告
    </p>
        <img style={{ width: '100%' }} alt="tu" src={process.env.PUBLIC_URL + '/static/img/appchild.png'} />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <WhiteSpace />
        <Button type="primary" onClick={this.toBind} style={{ height: '48px', borderRadius: '24px', backgroundColor: "#30CC75", outline: "none" }}>
          绑定孩子账号
      </Button>

        <WhiteSpace />
      </div>
    )
  }
}

class App extends React.Component {
  componentDidMount(){
    
  }
  render() {
    return (
      <BrowserRouter>

        <Route path='/' exact component={indexpage}></Route>
        <Route path='/bind' component={Bind}></Route>
        <Route path='/student' component={Student}></Route>
        <Route path='/studentlist' component={StudentList}></Route>
        <Route path='/mimaerror' component={MimaError}></Route>


      </BrowserRouter>

    )
  }
}


export default App;
