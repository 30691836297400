import React from 'react'
import { WhiteSpace ,Button,Toast } from 'antd-mobile';
import {axiosGet} from './server/serverapp';
class Student extends React.Component{
   
    toNextPromise = async () => {
        let user = this.props.location.state.dotData.user_id;
        const islogin = await axiosGet('saveStudent',{'user_id':user})
        
        if(islogin.retCode == 0){
            this.props.history.push('/studentlist')
        }else{
            Toast.info(islogin.retMsg, 3);
        }
        //this.props.history.push('/studentlist')
    }
    componentDidMount(){
        document.title = '确认绑定'
    }
    render(){
        let inputimg = [process.env.PUBLIC_URL + '/static/img/bindback.png',process.env.PUBLIC_URL + '/static/img/see.png']
        return (
            <div>
                <div style={{position:'relative'}}>
                    <img style={{ width: '100%' }} src={inputimg[0]} />
                </div>
                <div style={{width:'90%',height:'300px',backgroundColor:"#FFFFFF",position:'absolute',top:'140px',left:'5%',borderRadius:'16px',overflow:'hidden'}}>
                    <p style={{fontSize:"20px",fontWeight:"bold",textAlign:"center",marginTop:"10px"}}>
                    请确认孩子信息
                    </p>
                    <div style={{width:"94%",height:"200px",backgroundImage: 'url('+inputimg[1]+')',backgroundSize:"100% 100%",margin:"40px auto 0",position:'relative',paddingTop:"1px"}}>
                        <div style={{width:"52px",height:"52px",position:"absolute",top:"-26px",left:"50%",marginLeft:"-26px",backgroundColor:"#F9F9F9",borderRadius:"50%",overflow:"hidden"}}>
                            <img style={{width:'100%'}} src={this.props.location.state.dotData.user_avatar} />
                        </div>
                        <p style={{fontSize:"16px",marginTop:"33px",textAlign:"center"}}>
                            {this.props.location.state.dotData.user_name}
                        </p>
                        <div style={{textAlign:"left",textIndent:"28%"}}>
                            <p style={{fontSize:"16px",marginTop:"30px"}}>
                                <span style={{color:"#30CC75"}}>账号：</span>
                                <span>{this.props.location.state.dotData.user_phone}</span>
                            </p>
                            <p style={{fontSize:"16px"}}>
                                <span style={{color:"#30CC75"}}>学校：</span>
                                <span>{this.props.location.state.dotData.school_name}</span>
                            </p>
                            <p style={{fontSize:"16px"}}>
                                <span style={{color:"#30CC75"}}>班级：</span>
                                <span>{this.props.location.state.dotData.class_name}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace /><WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <WhiteSpace />
                <Button type="primary" onClick={this.toNextPromise} style={{ width:"90%",height:'48px',borderRadius:'24px',backgroundColor: "#30CC75", outline: "none", margin:"0 auto"}}>
          确认绑定
      </Button>
            </div>
        )
    }
}



export default Student